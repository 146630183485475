import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CalGuardService } from '@cvx/cal-angular';
import { CMOHomeComponent } from './cmo-home/cmo-home.component';
import { NgloComponent } from './nglo/nglo.component';

const routes: Routes = [
  {
    path: 'nglo',
    component: NgloComponent,
    loadChildren: () => import('./nglo/nglo.module').then(m => m.NgloModule),
    canActivate:[
          CalGuardService
        ]
  },
  {
    path: '',
    component: CMOHomeComponent,
    pathMatch: 'full',
    canActivate:[
          CalGuardService
        ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
